<template>
    <div>
        <b-card>
            <h3>
                My Team
            </h3>
            <div class="row mt-4" v-for="referral in referrals" :key="referral.id">
                <div class="col-3 d-flex">
                    <div>
                        <b-avatar :src="'https://elvwalletapi.com/uploads/users/' + referral.image " variant="info"></b-avatar>
                    </div>
                    <div style="margin-left:20px">
                        <div style="font-weight: 500; font-size: 18px; line-height: 24px;">
                            {{ referral.names }}
                        </div>
                        {{ referral.username }}
                    </div>
                </div>
                <div class="col-9 text-right">
                     <b-avatar-group size="40px">
                        <b-avatar :src="'https://elvwalletapi.com/uploads/users/' + child.image" variant="info" v-for="child in referral.users.slice(0, 5)" :key="child.id" />
                        <b-avatar :text="referral.users.length - 5" variant="primary" />
                    </b-avatar-group>
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    data () {
        return {
            loading: false,
            totals: {
                first: 0,
                second: 0,
                third: 0
            }
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('user', ['getReferrals']),
        getData() {
            this.loading = true
            this.getReferrals().then(response => {
                this.loading = false
            })
        }
    },
    computed: {
        ...mapState('user', ['referrals'])
    }
}
</script>
<style>
    .cont-icon{
        border-radius: 20px;
    }
    .b-avatar-group .b-avatar-group-inner{
        display: flow-root;
    }
</style>