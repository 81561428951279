<template>
    <b-card class="wallet" :id="wallet.currency" :class="isActive == wallet.id ? 'bg-active' : ''">
        <div class="row">
            <div class="col-12">
                <h4  v-if="wallet.asset">
                    {{ wallet.asset.name }}
                </h4>
            </div>
        </div>
        <div class="mt-3">
            <div class="address">
                {{ wallet.address }}
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-6">
                <h6>
                    {{ wallet.balance }} {{ wallet.asset.token }}
                </h6>
                <div>
                    ${{ wallet ? wallet.balance_usd.toFixed(2) : 0 }} USD
                </div>
                <div v-if="wallet.currency == 'elv'" style="font-size:13px">
                    {{ bsc.toFixed(5) }} BSC
                </div>
            </div>
            <div class="col-6 text-right pt-3">
                <img :src="wallet.asset.icon" alt="">
            </div>
        </div>
    </b-card>
</template>
<script>
import { mapActions } from 'vuex'
import Chart from './SecondChart.vue'
export default {
    components: {
        Chart
    },
    props: {
        wallet: {
            required: true
        },
        isActive: {
            default: false
        },
        isLock: {
            default: false
        }
    },
    data () {
        return {
            bsc: 0
        }
    },
    methods: {
        ...mapActions('wallet', ['getBalance'])
    },
    watch: {
        isActive: function (val) {
            if(val == this.wallet.id) {
                this.getBalance({address: this.wallet.address, currency: this.wallet.currency}).then(response => {
                    this.wallet.balance = response[0]
                    this.wallet.balance_usd = response[1]
                    this.bsc = response[2]
                })
            }
        }
    }
}
</script>
<style scoped>
    .address{
        width: 100%;
        white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .wallet img{
        max-width: 40px;
    }
    .wallet .price {
        font-weight: 300;
        font-size: 22px;
        line-height: 32px;
    }
    .price-usd{
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
    .bg-active{
        border: 3px solid #1A16BA !important;
        border-radius: 35px !important;
        transition: .3s all;
    }
</style>