<template>
    <div>
        <div class="row">
            <div class="col-12 mb-3">
                <WalletSlider />
            </div>
            <div class="col-12 col-sm-6 col-md-6 mb-3">
                <Wallets />
            </div>
            <div class="col-12 col-sm-6 col-md-6 mb-3">
                <Refereal />
            </div>
            <div class="col-12 mt-4 mb-3">
                <Team />
            </div>
        </div>
    </div>
</template>
<script>
import WalletSlider from './WalletSlider.vue'
import Wallets from './Wallets.vue'
import Team from './Team.vue'
import Refereal from './Refereal.vue'
export default {
    components: {
        WalletSlider,
        Wallets,
        Team,
        Refereal
    }
    
}
</script>