<template>
    <b-card class="text-center">
        <h2>
            Refereal friend
        </h2>
        <img src="@/assets/images/referal.png" alt="">
        <Copy :address="'https://myelvwallet.com/#/auth/signup/' + user.username " class="mt-5" style="margin-left:2%" />
    </b-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState('auth', ['user', 'code'])
    }
}
</script>